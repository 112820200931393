// import mzr from "rdmkit-mzr";
// import cpl from "rdmkit-cpl";
// import vrt from "rdmkit-vrt";
// import rdm from "rdmkit-rdm";

// import "./pug/pug.config.js";
// import "./data/index.js";

// const yam = require("./data/data.yaml");
// console.log(yam.hello[1]);

// mzr();
// cpl();
// vrt({ aboveColor: "green" });
// rdm(".feature");

import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

// Show menu-nav
const btnMenu = document.getElementById("btn-menu");
const navMenu = document.getElementById("menu-nav");
const menuAnchor = document.getElementById("menu-anchor");

const btnCater = document.getElementById("btn-cater");
const caterAnchor = document.getElementById("cater-anchor");
btnCater.addEventListener("click", function() {
  anchorAnimation(caterAnchor, "center");
});

let menuActive = false;

btnMenu.addEventListener("click", function() {
  navMenu.classList.toggle("show-nav");
  if (menuActive === false) {
    anchorAnimation(menuAnchor, "center");
  }
  menuActive = !menuActive;
});

// Navigate menu items
const btnApps = document.getElementById("btn-apps");
const btnSalad = document.getElementById("btn-salad");
const btnCondiments = document.getElementById("btn-condiments");
const btnTandoori = document.getElementById("btn-tandoori");
const btnVegetables = document.getElementById("btn-vegetables");
const btnRice = document.getElementById("btn-ride");
const btnBread = document.getElementById("btn-bread");
const btnSpecialities = document.getElementById("btn-specialities");
const btnChicken = document.getElementById("btn-chicken");
const btnGoat = document.getElementById("btn-goat");
const btnDesserts = document.getElementById("btn-desserts");
const btnBeverages = document.getElementById("btn-beverages");

const appetizersAnchor = document
  .getElementById("Appetizers")
  .getElementsByTagName("h2")[0];
const saladAnchor = document
  .getElementById("Salad")
  .getElementsByTagName("h2")[0];
const condimentsAnchor = document
  .getElementById("Condiments")
  .getElementsByTagName("h2")[0];
const tandooriAnchor = document
  .getElementById("Tandoori")
  .getElementsByTagName("h2")[0];
const vegetablesAnchor = document
  .getElementById("Vegetable")
  .getElementsByTagName("h2")[0];
const riceAnchor = document
  .getElementById("Rice")
  .getElementsByTagName("h2")[0];
const breadAnchor = document
  .getElementById("Bread")
  .getElementsByTagName("h2")[0];
const specialitiesAnchor = document
  .getElementById("Speciality")
  .getElementsByTagName("h2")[0];
const chickenAnchor = document
  .getElementById("Chicken")
  .getElementsByTagName("h2")[0];
const goatAnchor = document
  .getElementById("Goat")
  .getElementsByTagName("h2")[0];
const dessertsAnchor = document
  .getElementById("Desserts")
  .getElementsByTagName("h2")[0];
const beveragesAnchor = document
  .getElementById("Beverages")
  .getElementsByTagName("h2")[0];

btnApps.addEventListener("click", function() {
  anchorAnimation(appetizersAnchor);
});
btnSalad.addEventListener("click", function() {
  anchorAnimation(saladAnchor);
});
btnCondiments.addEventListener("click", function() {
  anchorAnimation(condimentsAnchor);
});
btnTandoori.addEventListener("click", function() {
  anchorAnimation(tandooriAnchor);
});
btnVegetables.addEventListener("click", function() {
  anchorAnimation(vegetablesAnchor);
});
btnRice.addEventListener("click", function() {
  anchorAnimation(riceAnchor);
});
btnBread.addEventListener("click", function() {
  anchorAnimation(breadAnchor);
});
btnSpecialities.addEventListener("click", function() {
  anchorAnimation(specialitiesAnchor);
});
btnChicken.addEventListener("click", function() {
  anchorAnimation(chickenAnchor);
});
btnGoat.addEventListener("click", function() {
  anchorAnimation(goatAnchor);
});
btnDesserts.addEventListener("click", function() {
  anchorAnimation(dessertsAnchor);
});
btnBeverages.addEventListener("click", function() {
  anchorAnimation(beveragesAnchor);
});

// Hours
const btnHours = document.getElementById("btn-hours");
const hoursBox = document.getElementById("hours-box");
btnHours.addEventListener("click", function() {
  anchorAnimation(hoursBox);
});

function anchorAnimation(anchor, position = "center") {
  smoothScrollIntoView(anchor, { behavior: "smooth", block: position });
  anchor.classList.add("animate-flicker");
  setTimeout(() => {
    anchor.classList.remove("animate-flicker");
  }, 3000);
}
